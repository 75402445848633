import {GetSettingsInput, OverrideSettingsInput} from '@/settings/types';
import {getMetaobject} from '@/shopify-storefront/metaobjects';
import {MetaobjectBase} from '@/shopify-storefront/types';
import {logError} from '@/utilities/log';
import {withDeduplication} from '@/utilities/with-deduplication';
import {signal} from '@preact/signals';
import {MetaobjectHandleInput} from '@shopify/hydrogen-react/storefront-api-types';

const settingsMap = signal(new Map<string, MetaobjectBase>());

/**
 * Gets the settings object for a given handle. Initially returns `null`,
 * but will update to the settings object once it has been loaded.
 *
 * The settings store uses a Preact signal to store settings objects.
 * Any component or effect that uses the `getSettings` function will
 * automatically re-render when the settings object is updated.
 *
 * `type` and `handle` map directly to the metaobject type and handle.
 * If the settings metaobject has references, they will be followed
 * up to the number of levels specified by `followReferences`.
 * This allows us to use "nested" settings objects.
 *
 * @example
 * ```ts
 * // Get the settings for the current page.
 * const pageSettings = getSettings<PageSettings>({
 *   type: 'cart_section',
 *   handle: 'default-cart-section-settings',
 *   followReferences: 1,
 * });
 * ```
 */
export function getSettings<T>(input: GetSettingsInput): T | null {
  const key = createKey(input);
  const settings = settingsMap.value.get(key);

  if (!settings) {
    const handleError = (error: Error) => {
      logError(error, input);
    };
    withDeduplication(key, () => loadSettings(input)).catch(handleError);
    return null;
  }

  return settings as T;
}

/**
 * Overrides a new settings object to replace an existing one.
 * This allows us to hot-swap settings metaobjects without
 * having to reload the entire page.
 * @param newHandle - The handle of the new settings object.
 * @param oldHandle - The handle of the old settings object.
 * @param type - The type of the settings object.
 *
 * @example
 * ```ts
 * // Override the settings for the current page.
 * overrideSettings({
 *  newHandle: 'new-settings',
 *  oldHandle: 'old-settings',
 *  type: 'page',
 * });
 * ```
 */
export function overrideSettings({
  followReferences,
  newHandle,
  oldHandle,
  type,
}: OverrideSettingsInput) {
  const input = {
    followReferences,
    handle: newHandle,
    type,
  };
  const overrideKey = createKey({type, handle: oldHandle});
  const handleError = (error: Error) => {
    logError(error, {...input, overrideKey});
  };
  loadSettings(input, overrideKey).catch(handleError);
}

function createKey({type, handle}: MetaobjectHandleInput) {
  return `${type}:${handle}`;
}

/**
 * Loads the settings object for a given handle.
 * By default, the settings object will be loaded once.
 * If the `overrideKey` is provided, the settings object
 * will be loaded and stored under that key.
 * This prevents race conditions between `getSettings` and `overrideSettings`.
 */
async function loadSettings(input: GetSettingsInput, overrideKey?: string) {
  const {followReferences, ...handle} = input;
  const settings = await getMetaobject({handle, followReferences});

  if (!settings)
    throw new Error(`Settings not found: ${handle.type} ${handle.handle}`);

  // To overwrite a setting, the overrideKey must be provided.
  // Otherwise, the setting should only load once.
  if (!overrideKey && settingsMap.value.has(createKey(handle))) {
    return;
  }
  settingsMap.value = new Map([
    ...settingsMap.value,
    [overrideKey ?? createKey(handle), settings],
  ]);
}
