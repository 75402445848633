import {
  GetMetaobjectInput,
  Metaobject,
  MetaobjectBase,
  MetaobjectResponse,
  ParsedMetafield,
} from '@/shopify-storefront/types';
import {
  Metafield,
  MetaobjectHandleInput,
} from '@shopify/hydrogen-react/storefront-api-types';
import {
  getMetaobjectByHandleQuery,
  getMetaobjectQuery,
} from '@/shopify-storefront/queries';

import {PartialObjectDeep} from 'type-fest/source/partial-deep';
import {client} from '@/shopify-storefront/client';
import {createVariant} from '@/product/product';
import {parseMetafield} from '@shopify/hydrogen-react';
import {variantMetafieldIdentifiers} from '@/product/product-store';

/**
 * Fetches a metaobject by its handle or ID.
 * If the metaobject contains any metaobject references, they will be fetched
 * and parsed as well. References will be fetched recursively up to the depth
 * specified by `followReferences`.
 */
export async function getMetaobject<T extends MetaobjectBase>({
  followReferences = 0,
  handle,
  id,
}: GetMetaobjectInput): Promise<T | null> {
  const getter = !!id
    ? () => getMetaobjectById(id)
    : () => getMetaobjectByHandle(handle!);
  const metaobject = await getter();
  if (!metaobject) return null;

  const getParsed = (field: Metafield) =>
    getParsedMetafield(field, followReferences);
  const parsedFields = await Promise.all(metaobject.fields.map(getParsed));

  const metaobjectTransformed = Object.assign(
    Object.fromEntries(parsedFields),
    metaobject
  ) as T & {fields?: unknown};
  delete metaobjectTransformed.fields;
  return metaobjectTransformed;
}

/**
 * Fetches a metaobject by its handle.
 */
async function getMetaobjectByHandle(
  handle: MetaobjectHandleInput
): Promise<Metaobject | null> {
  const {metaobject} = await client.query<MetaobjectResponse>(
    getMetaobjectByHandleQuery,
    {
      handle,
      variant_metafields: variantMetafieldIdentifiers,
    }
  );
  return metaobject ?? null;
}

/**
 * Fetches a metaobject by its ID.
 */
async function getMetaobjectById(id: string): Promise<Metaobject | null> {
  const {metaobject} = await client.query<MetaobjectResponse>(
    getMetaobjectQuery,
    {
      id,
      variant_metafields: variantMetafieldIdentifiers,
    }
  );
  return metaobject ?? null;
}

/**
 * Parses a metafield and optionally fetches any references.
 * References will be fetched recursively up to the depth specified by
 * `followReferences`.
 */
async function getParsedMetafield(
  field: PartialObjectDeep<Metafield, {recurseIntoArrays: true}>,
  followReferences: number
): Promise<[string, unknown]> {
  const {key, type, value} = field;
  const isReference = type === 'metaobject_reference';
  const shouldFollowReference = !!value && followReferences > 0;
  let parsedValue: unknown = value;

  if (!isReference) {
    parsedValue = transformReferenceField(
      parseMetafield<ParsedMetafield>(field)
    );
  } else if (shouldFollowReference) {
    parsedValue = await getMetaobject({
      id: value,
      // only recurse to the depth of `followReferences`
      followReferences: followReferences - 1,
    });
  }

  return [key, parsedValue] as [string, unknown];
}

/**
 * Apply additional transformations to the parsed metafield based on its type.
 * This allows us to convert 'reference' type fields into their respective
 * internal types. For example, a 'list.variant_reference' field will be
 * converted into a list of [ProductVariant] objects.
 *
 * If the metafield is not a reference, the parsed value will be returned as is.
 * If the reference value is falsy, the parsed value will be returned as is.
 *
 * @param parsedValue - The parsed value of the metafield
 * @param type - The type of the metafield
 * @returns The transformed value of the metafield
 */
function transformReferenceField({type, parsedValue}: ParsedMetafield) {
  if (!type.includes('reference') || !parsedValue) {
    return parsedValue;
  }

  switch (type) {
    case 'list.variant_reference':
      return parsedValue.map(createVariant);
    default:
      return parsedValue;
  }
}
