import {productVariantFieldsFragment} from '@/product/fragments';

/**
 * A fragment that fetches all fields of a metaobject.
 * It follows references to get lists of product variants.
 */
export const metaobjectFieldsFragment = /* GraphQL */ `
  fragment MetaobjectFields on Metaobject {
    handle
    id
    fields {
      key
      type
      value
      references(first: 100) {
        edges {
          node {
            ...ProductVariantFields
          }
        }
      }
    }
  }
  ${productVariantFieldsFragment}
`;
