import {metaobjectFieldsFragment} from '@/shopify-storefront/fragments';

export const getMetaobjectByHandleQuery = /* GraphQL */ `
  query getMetaobject(
    $handle: MetaobjectHandleInput
    $variant_metafields: [HasMetafieldsIdentifier!]!
  ) {
    metaobject(handle: $handle) {
      ...MetaobjectFields
    }
  }

  ${metaobjectFieldsFragment}
`;

export const getMetaobjectQuery = /* GraphQL */ `
  query getMetaobject(
    $id: ID!
    $variant_metafields: [HasMetafieldsIdentifier!]!
  ) {
    metaobject(id: $id) {
      ...MetaobjectFields
    }
  }

  ${metaobjectFieldsFragment}
`;
